import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Divider,
  Heading,
  HStack,
  Image,
  Link,
  ListItem,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import ReactAudioPlayer from "react-audio-player";

interface IPerson {
  name: string;
  socialHandleLink?: string;
}

interface ISpouse {
  name: string;
  ex?: boolean;
  socialHandleLink?: string;
}
interface IMedia {
  displayName: string;
  link?: string;
}

interface IFraudCardProps {
  fraudsterImage?: string;
  fraudsterName: string;
  fraudsterDescription: string | JSX.Element;
  partners?: Array<IPerson>;
  spouses?: Array<ISpouse>;
  techniques?: string[];
  estimatedMoneyLost: string;
  voiceSamples?: string[];
  educationalMedia?: Array<IMedia>;
  images?: Array<string>;
  documents?: string[];
  socialMediaLinks: React.ReactNode;
}

export default function FraudCard({
  fraudsterImage,
  fraudsterName,
  fraudsterDescription,
  estimatedMoneyLost,
  techniques,
  spouses,
  partners,
  educationalMedia,
  images,
  voiceSamples,
  documents,
  socialMediaLinks,
}: IFraudCardProps) {
  const openPDF = useCallback(
    (document: string) => () => {
      const fileUrl = document;
      window.open(fileUrl, "_blank");
    },
    []
  );

  return (
    <Box>
      <Center>
        <Card maxW="2xl">
          <CardBody>
            <Center>
              <Image
                src={fraudsterImage}
                alt="Green double couch with wooden legs"
                borderRadius="lg"
                width={"sm"}
              />
            </Center>
            <Stack mt="6" spacing="3">
              <Center>
                <Heading size="md">{fraudsterName}</Heading>
              </Center>
              {fraudsterDescription}
              <Text fontWeight={600}>
                {`Techniques ${fraudsterName} use to defraud victims`}
              </Text>
              <UnorderedList spacing={"2"}>
                {techniques?.map((technique) => (
                  <ListItem>{technique}</ListItem>
                ))}
              </UnorderedList>
            </Stack>
          </CardBody>
          <Divider />
          <CardBody>
            <TableContainer>
              <Table variant="simple">
                <TableCaption>
                  Act with extreme caution. You have been warned.
                </TableCaption>

                <Tbody>
                  <Tr>
                    <Td>Partners</Td>
                    <Td>
                      {partners?.map((partner) =>
                        partner.socialHandleLink ? (
                          <Link
                            href={partner.socialHandleLink}
                            isExternal
                            color={"blue.600"}
                          >
                            {partner.name}
                          </Link>
                        ) : (
                          <Text>{partner.name}</Text>
                        )
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Estimated Money Stolen</Td>
                    <Td>{estimatedMoneyLost}</Td>
                  </Tr>
                  <Tr>
                    <Td>Social Media Links</Td>
                    <Td>
                      <Text>{socialMediaLinks}</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Victims Count</Td>
                    <Td>8 or more</Td>
                  </Tr>
                  <Tr>
                    <Td>Martial Status</Td>
                    <Td>Divorced</Td>
                  </Tr>
                  {spouses && (
                    <Tr>
                      <Td>Spouse(s)</Td>
                      <Td>
                        {spouses?.map((spouse) => (
                          <>
                            <Link
                              href={spouse.socialHandleLink}
                              isExternal
                              color={"blue.500"}
                            >
                              {spouse.name}
                            </Link>
                            <span> {spouse.ex ? "(ex)" : ""} </span>
                          </>
                        ))}
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Voice Sample</Td>
                    <Td>
                      <VStack
                        alignSelf={"flex-start"}
                        spacing={"2"}
                        left={-10}
                        position={"relative"}
                      >
                        {voiceSamples?.map((voiceSample) => (
                          <ReactAudioPlayer src={voiceSample} controls />
                        ))}
                      </VStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Documents Used</Td>
                    <Td>
                      <HStack>
                        {documents?.map((document) => (
                          <Image
                            maxW={10}
                            src={require("../assets/pdficon.avif")}
                            onClick={openPDF(document)}
                            cursor={"pointer"}
                          />
                        ))}
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Investigation</Td>
                    <Td> In Progress </Td>
                  </Tr>
                  <Tr>
                    <Td>Related Media</Td>
                    <Td>
                      <VStack alignItems={"self-start"}>
                        {images?.map((image) => (
                          <Image maxW={200} src={image} />
                        ))}
                      </VStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Educational Content</Td>
                    <Td>
                      <VStack
                        position={"relative"}
                        color="blue.600"
                        alignItems={"flex-start"}
                      >
                        {educationalMedia?.map((media) => (
                          <Link href={media.link} isExternal>
                            {media.displayName}
                          </Link>
                        ))}
                      </VStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
          <CardFooter>
            <Center width={"100%"}>
              <Link href="mailto:injustice.crush@gmail.com" isExternal mt={-6}>
                Can help?
                <Button variant="solid" colorScheme="blue" ml={2}>
                  Get in touch
                </Button>
              </Link>
            </Center>
          </CardFooter>
        </Card>
      </Center>
    </Box>
  );
}
